<template>
  <vue-ueditor-wrap :value="value" @input="change" :config="myConfig" @beforeInit="addCustomButtom"/>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap"

export default {
  props: {
    value: String,
    initialHeight: Number
  },
  components: {
    VueUeditorWrap
  },
  computed: {
    myConfig () {
      return {
        enableContextMenu: true,
        // contextMenu: [],
        autoHeightEnabled: false,
        initialFrameHeight: this.initialHeight || 240,
        initialFrameWidth: "100%",
        // UEDITOR_HOME_URL: "/UEditor/"
      }
    }
  },
  methods: {
    addCustomButtom (editorId) {
      const that = this
      window.UE.registerUI('test-button', function (editor, uiName) {
        // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
        // editor.registerCommand(uiName, {
        //   execCommand: function () {
        //     editor.execCommand('inserthtml', `<span>这是一段由自定义按钮添加的文字</span>`)
        //   }
        // })

        // 创建一个 button
        var btn = new window.UE.ui.Button({
          // 按钮的名字
          name: uiName,
          // 提示
          title: '点击此处添加循环项',
          // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
          cssRules: `background-image: url('/UEditor/themes/default/images/copy.png') !important;background-size: cover`,
          // 点击时执行的命令
          onclick: function () {
            // 这里可以不用执行命令，做你自己的操作也可
            // editor.execCommand(uiName)
            const str0 = editor.selection.getRange().endContainer.outerHTML
            console.log(str0);
            const str1 = str0 && str0.match(/loop(\S*)</)[1].trim();
            console.log(str1);
            // const arr0 = str0.split('</span>')
            const str = editor.getContent()
            // console.log(arr0[0])
            // console.log(str)
            const arr = str.split('</tr>')
            // console.log(arr);
            let seleStr = ''
            for (let i = 0; i < arr.length; i++) {
              console.log(arr[i]);
              // console.log(arr[i].startsWith(arr0[0]));
              if (arr[i].indexOf(str1) !== -1) {
                seleStr = `${arr[i]}</tr>`
              }
            }
            console.log(seleStr);
            if (seleStr !== '') {
              sessionStorage.setItem("contentHtml", `${seleStr}`); // 存入 参数： 1.调用的值 2.所要存入的数据
              that.$message({
                message: '选择成功',
                type: 'success'
              });
            } else {
              that.$message({
                message: '选择失败',
                type: 'error'
              });
            }
            // that.getInfo(editor.selection.getRange().endContainer)
            // const contentHtml = editor.selection.getRange().endContainer.parentNode.innerHTML;
            // let content1 = editor.selection.getRange().endContainer.parentNode.innerHTML;
            // console.log(content1)
            // if (content1.indexOf('<tr') !== -1) {
            //   content1 = editor.selection.getRange().endContainer.parentNode.parentNode.innerHTML;
            // }
            // sessionStorage.setItem("contentHtml", contentHtml); // 存入 参数： 1.调用的值 2.所要存入的数据
            // that.$message({
            //   message: '选择成功',
            //   type: 'success'
            // });
          }
        })

        // 当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', function () {
          var state = editor.queryCommandState(uiName)
          if (state === -1) {
            btn.setDisabled(true)
            btn.setChecked(false)
          } else {
            btn.setDisabled(false)
            btn.setChecked(state)
          }
        })

        // 因为你是添加 button，所以需要返回这个 button
        return btn
      }, 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */, editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */)
    },
    getInfo (info) {
      // console.log(info);
      const contentHtml = info.parentNode.innerHTML
      if (contentHtml.indexOf('<td') !== -1) {
        sessionStorage.setItem("contentHtml", `${contentHtml}`); // 存入 参数： 1.调用的值 2.所要存入的数据
        // console.log(`<tr>${contentHtml}</tr>`)
        this.$message({
          message: '选择成功',
          type: 'success'
        });
      } else if (contentHtml.indexOf('<body') !== -1) {
      } else {
        this.getInfo(info.parentNode)
      }
    },
    change (text) {
      this.$emit("input", text)
    }
  }
}
</script>

<style>
.edui-default .edui-button-body {
  line-height: 20px;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body {
  line-height: 20px;
}
</style>
