<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3>
        <el-tooltip
          class="item"
          effect="dark"
          content="返回上一页"
          placement="bottom"
        >
          <i
            @click="goBack"
            style="margin-right: 40px; cursor: pointer"
            class="el-icon-arrow-left"
          ></i>
        </el-tooltip>
        添加新闻
      </h3>
    </div>
    <div style="width: 60%">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="资讯类型">
          <el-radio v-model="form.type" :label="1">普通资讯</el-radio>
          <el-radio v-model="form.type" :label="2">使用手册</el-radio>
        </el-form-item>
        <el-form-item label="新闻分类">
          <el-cascader
            v-model="form.cid"
            :props="optionProps"
            :options="options"
            @change="setclass"
            ref="cascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="新闻名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="新闻标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="新闻关键词">
          <el-input v-model="form.keyword"></el-input>
        </el-form-item>
				<el-form-item label="虚拟浏览数">
				  <el-input type="number" v-model="form.invented_views"></el-input>
				</el-form-item>
				<el-form-item label="虚拟点赞数">
				  <el-input type="number" v-model="form.likes_num_invented"></el-input>
				</el-form-item>
        <el-form-item label="虚拟分享数">
          <el-input type="number" v-model="form.share_num_invented"></el-input>
        </el-form-item>
				<el-form-item label="文章简介">
				  <el-input type="textarea" v-model="form.introduction"></el-input>
				</el-form-item>

        <el-form-item label="添加封面">
          <el-upload
            class="avatar-uploader"
            :action="serverUrl"
            name="image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :headers="header"
            :data="data"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="form.thumbnail"
              :src="imgurl + form.thumbnail"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- <el-upload class="avatar-uploader-editor" :action="serverUrl" name="image" :headers="header" :data="data" :show-file-list="false"
		  :on-success="uploadSuccess" :before-upload="be_up" :on-error="uploadError"> </el-upload> -->
        </el-form-item>

        <el-form-item label="是否精选">
          <el-radio v-model="form.is_choice" :label="1">精选</el-radio>
          <el-radio v-model="form.is_choice" :label="0">不精选</el-radio>
        </el-form-item>
        <el-form-item label="新闻内容">
		      <uEditor id="editor" v-model="form.content"></uEditor>
          <!-- <vue-ueditor-wrap
            v-model="form.content"
            :config="editorConfig"
          ></vue-ueditor-wrap> -->
        </el-form-item>
        <!-- <el-form-item label="选择关联商品">
          <el-button plain size="small" @click="chooseProduct"
            >点击选择</el-button
          >
          <div class="selectedProList">
            <div
              class="selectedProItem"
              v-for="(item2, index2) in selectProList"
              :key="index2"
            >
              <img :src="imgurl + item2.cover_pic" alt="" srcset="" />
              <div class="probottom">
                <p>{{ item2.name }}</p>
                <span>{{ item2.price }}</span>
              </div>
            </div>
          </div>
        </el-form-item> -->

        <el-form-item label="选择关联文章">
          <el-button plain size="small" @click="choosenewslist"
            >点击选择</el-button
          >
          <div class="selectedNewList">
            <div
              class="selectedNewItem"
              v-for="(item2, index2) in selectNewsList"
              :key="index2"
            >
              <div class="newbottom">
                <p>{{ item2.name }}</p>
                <span>{{ item2.price }}</span>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="新闻链接">
          <el-input v-model="form.content" placeholder="请输入文章链接"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
     <!-- <el-dialog title="选择商品" :visible.sync="chooseprodialog" width="40%">
      <div class="productList">
        <div
          class="selectedProduct"
          v-for="(item, index) in multipleSelection"
          :key="index"
        >
        </div>
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="liveProductList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="setCheckout"
        >
          <el-table-column reserve-selection type="selection" width="55">
          </el-table-column>
          <el-table-column label="商品ID" width="120">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="300">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品价格"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.price }}</template>
          </el-table-column>
        </el-table>
        <el-button @click="toggleSelection(data)">切换第二、第三行的选中状态</el-button>
        <el-pagination
          style="margin-top: 20px"
          @current-change="crtChg"
          :current-page="livproForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseprodialog = false">取 消</el-button>
        <el-button type="primary" @click="chooseProSele">确 定</el-button>
      </span>
    </el-dialog> -->

    <el-dialog title="选择新闻" :visible.sync="choosenewsdialog" width="30%">
      <div class="newsList">
        <div
          class="selectedNews"
          v-for="(item, index) in multipleSelection"
          :key="index"
        >
        </div>
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="newsDataList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="setCheckout"
        >
          <el-table-column reserve-selection type="selection" width="55">
          </el-table-column>
          <el-table-column label="ID" width="120">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="name" label="新闻标题" width="300">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @current-change="crtChg"
          :current-page="livproForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="choosenewsdialog = false">取 消</el-button>
        <el-button type="primary" @click="chooseNewsSele">确 定</el-button>
      </span>
    </el-dialog>

  </el-card>
</template>
<script>
/***
 *                    _ooOoo_
 *                   o8888888o
 *                   88" . "88
 *                   (| -_- |)
 *                    O\ = /O
 *                ____/`---'\____
 *              .   ' \\| |// `.
 *               / \\||| : |||// \
 *             / _||||| -:- |||||- \
 *               | | \\\ - /// | |
 *             | \_| ''\---/'' | |
 *              \ .-\__ `-` ___/-. /
 *           ___`. .' /--.--\ `. . __
 *        ."" '< `.___\_<|>_/___.' >'"".
 *       | | : `- \`.;`\ _ /`;.`/ - ` : | |
 *         \ \ `-. \_ __\ /__ _/ .-` / /
 * ======`-.____`-.___\_____/___.-`____.-'======
 *                    `=---='
 *
 * .............................................
 *          佛祖保佑             永无BUG
 */
// editor
import { baseURL } from "@/api/https.js";
// import editor from "../../components/editor/index.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { findAllNewsCategory, newsSave,NewsList,findNewsChoiceGoods} from "@/api/api.js";
// import UpImg from "@/components/upImg.vue";
import uEditor from "@/components/uEditor/uEditor";
// import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: {
	  uEditor
    // editor,
    // VueUeditorWrap,
    // UpImg
  },
  data() {
    return {
      header: { AdminToken: localStorage.getItem("admin_token") },
      serverUrl: baseURL + "/backend/uploadsfile/upload_image", //上传的图片服务器地址
      data: {
        type: "addnews",
      },
      imgurl: "",
      imageUrl: "",
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      options: [],
      content: "",
      form: {
        // radio:1,
        name: "",
        cid: "",
        type: 1,
        title: "",
        keyword: "",
        thumbnail: "",
        content: "",
        author_avatar: "",
        author_name: "",
        author_synopsis: "",
        goods_id: [],
        is_choice:'',
        relation_news:'',
        invented_views: '',
        introduction: '',
        likes_num_invented: '',
        share_num_invented: ''
      },
      chooseprodialog: false,
      choosenewsdialog:false,
      selectProList: [],
      selectNewsList: [],
      newsDataList:[],
      selectProListAll: [],
      multipleSelection: [],
      multipleSelection2: [],
      liveProductList: [],
      total: '',
      livproForm: {
        limit: 10,
        page: 1,
      },
      editorConfig: {
        autoHeightEnabled: false, //编译器不自动被内容撑高
        initialFrameHeight: 600, //初始容器高度
        initialFrameWith: 600,
        serverUrl: "https://adminbayj.qiannianjituan.com/ueditor/uploaders/index", //上传文件地址
        UEDITOR_HOME_URL: "./static/UEditor/", //UEditor资源文件的存放路径
      },
    };
  },
  watch: {
    "form.content": function (newVal, oldVal) {
      console.log(newVal.length);
    },
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getlist();
    if (this.$route.query.row) {
      console.log(' this.selectNewsList=====',JSON.parse(this.$route.query.row))
      this.form = JSON.parse(this.$route.query.row);
      this.form.id = JSON.parse(this.$route.query.row).id;
      // this.form.goods_id = JSON.parse(this.$route.query.row).goods_id;
      this.selectNewsList = JSON.parse(this.$route.query.row).news;
      this.content = JSON.parse(this.$route.query.row).content;
      let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; // 匹配图片中的src
      let arr = this.content.match(imgReg); //筛选出所有的img
    }
  },

  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data.imgurl;
      this.form.thumbnail = res.data.imgurl;
    },
    beforeAvatarUpload(file) {
      console.log(file);
    },
    setclass(e) {
      this.form.cid = this.form.cid[this.form.cid.length - 1];
    },
    onSubmit() {
      this.newsSave();
    },
    goBack() {
      this.$router.back(-1);
    },
    // 添加
    async newsSave() {
      const { data } = await newsSave(this.form);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message({
        message: data.data,
        type: "success",
      });
      setTimeout(() => {
        this.$router.back(-1);
      }, 500);

      // this.list = data.data.classify_list;
      // this.options=data.data.classify_list;
    },
    // 获取新闻分类列表
    async getlist() {
      const { data } = await findAllNewsCategory({});
      if (data.code != 200) return this.$message.error(data.data);
      // this.list = data.data.classify_list;
      this.options = data.data.classify_list;
    },
    // 切换分页执行
    tableDefaultSelect() {
      this.type = 2;
      let rows = []; //先定义一个选中行的数组
      if (JSON.stringify(this.selectProListAll) !== "[]") {
        //先判定是否有值
        let arr = this.selectProListAll.flat(); //将数组进行降维打击
        for (let i in arr) {
          //ES6新出的findIndex，返回符合条件的索引位置，有则返回，无则返回-1，唯一标识id请根据实际修改
          if (this.newsDataList.findIndex((x) => x.id === arr[i].id) >= 0) {
            rows.push(
              this.newsDataList.findIndex((x) => x.id === arr[i].id)
            );
          }
        }
        this.$nextTick(() => {
          //等dom更新之后再选中，并且表格一定要设置ref
          if (rows) {
            rows.forEach((row) => {
              this.$refs.multipleTable.toggleRowSelection(
                this.newsDataList[row],
                true
              );
            });
          }
        });
      }
    },

    // 选择商品按钮
    // chooseProduct() {
    //   this.chooseprodialog = true;
    //   this.getProductlist();
    // },

    // 商品列表确定
    // chooseProSele() {
    //   this.selectProList = this.selectProListAll.flat();
    //   if (this.$route.query.id) {
    //   }
    //   this.form.goods_id = this.selectProList.map((item) => {
    //     return item.id;
    //   });
    //   this.chooseprodialog = false;
    // },

    // 选择新闻按钮
    choosenewslist() {
      this.choosenewsdialog = true;
      this.getNewslist();
    },

    // 新闻列表确定
    chooseNewsSele() {
      this.selectNewsList = this.selectProListAll.flat();
      if (this.$route.query.id) {
      }
      this.form.relation_news = this.selectNewsList.map((item) => {
        return item.id;
      });
      this.form.relation_news = this.form.relation_news.join(",")
      console.log(this.form.relation_news,'99999999999999')
      this.choosenewsdialog = false;
    },

    // 获取关联新闻列表
    async getNewslist() {
      const { data } = await NewsList(this.livproForm);
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.newsDataList = data.data.goods_list.data;
      this.total = data.data.goods_list.total;
      this.$nextTick(() => {
        this.selectNewsList.forEach((item) => {
          this.newsDataList.forEach((item2) => {
            if (item.id == item2.id) {
              console.log(item.id);
              this.$refs['multipleTable'].toggleRowSelection(item2, true);
            }
          });
        });
      });
    },

    // 表格选中项
    // setCheckout(e) {
    //   let ary = [];
    //   e.forEach((item) => {
    //     this.liveProductList.forEach((item2) => {
    //       if (item2.id == item.id) {
    //         ary.push(item);
    //       }
    //     });
    //   });
    //   this.selectProListAll[this.livproForm.page] = ary;
    // },
    setCheckout(e) {
      let ary = [];
      e.forEach((item) => {
        this.newsDataList.forEach((item2) => {
          if (item2.id == item.id) {
            ary.push(item);
          }
        });
      });
      this.selectProListAll[this.livproForm.page] = ary;
    },

    // 获取商品列表
    async getProductlist() {
      const { data } = await findNewsChoiceGoods(this.livproForm);
      if (data.code != 200) return this.$message.error(data.data);
      this.liveProductList = data.data.data;
      this.total = data.data.total;
      // 获取后渲染分页选中项
      this.$nextTick(() => {
        this.selectProList.forEach((item) => {
          this.liveProductList.forEach((item2) => {
            if (item.id == item2.id) {
              console.log(item.id);
              this.$refs['multipleTable'].toggleRowSelection(item2, true);
            }
          });
        });
      });
    },
    // 切换分页
    crtChg(e) {
      this.livproForm.page = e;
      this.getNewslist();
      // this.getProductlist();
      this.tableDefaultSelect();
    },
    demo() {
      let bar = "global";
      let obj1 = {
        bar: "obj1",
        foo: foo,
      };
      let obj2 = {
        bar: "obj2",
        foo: obj1
      };
      function foo() {
        console.log(bar);
      }
      foo() // global
      obj1.foo() // global
      // obj2.obj1.foo() // undefined
      foo.call(obj2)
      new foo()
    },
  },
  created() {
    this.demo();
  },
};
</script>
<style lang="less" scope>
.edit_container .ql-editing {
  left: 0 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
 .selectedProList {
        display: flex;
        flex-wrap: wrap;
        .selectedProItem {
          width: 100px;
          height: 175px;
          border: 1px solid #efefef;
          margin-right: 10px;
          img {
            width: 98px;
            height: 98px;
          }
          .probottom {
            padding: 6px;
            p {
              line-height: 20px;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 86px;
            }
            span {
              color: #ff4544;
            }
          }
        }
      }
</style>
